var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vld-parent"},[(_vm.reqBusy)?_c('div',[_c('loading',{attrs:{"active":true,"is-full-page":false}})],1):_vm._e(),(_vm.details)?_c('div',{staticClass:"grid space-x-2 space-y-2 lg:grid-cols-7 md:grid-cols-4"},[_c('div',{staticClass:"lg:col-span-4 md:col-span-4"},[_c('div',{staticClass:"col-span-4 grid-grid-cols-1"},[_c('info-grid',{attrs:{"title":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
            ),"gridClass":"sm:grid-cols-1 xs:grid-cols-2","data":_vm.getProfile(),"editPermissionStatus":_vm.$acl.canUpdate('vehicles')},on:{"edit":function($event){return _vm.onOpenES({ primaryKey: _vm.$route.params.id })}},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('div',{staticClass:"flex items-center -ml-5"},[_c('VueQrcode',{attrs:{"value":_vm.getQrCodeURL,"options":{ width: 160 }}}),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.copyToClipBoard()}}},[_c('oto-typography',{attrs:{"text":"QR Code","variant":"two"}}),_c('i',{staticClass:"ml-2 far fa-copy"})],1),_c('t-input',{ref:"code",staticClass:"-mx-4 -mt-4 break-all",attrs:{"value":_vm.getQrCode,"variant":"untraceable","readonly":"readonly"}})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"grid grid-cols-2 py-3"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleSpeed'
                  ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.getSpeed,"variant":"three"}})],1),_c('div',{staticClass:"grid grid-cols-2 py-3"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.description'
                  ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.getDescription,"variant":"three"}})],1)]},proxy:true}],null,false,1579002780)})],1),_c('content-section',{staticClass:"py-6"},[_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"text-xl font-semibold uppercase text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.gridTitle' ))+" ")])]),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.name'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.name,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockIMEI'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.lock_id,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockID'
              ),"variant":"two"}}),_c('oto-typography',{staticStyle:{"color":"blue"},attrs:{"text":("#" + (_vm.details.lock.id)),"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.ioTCategory'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.iot_category,"fallback":"--","variant":"three"}})],1),(_vm.isSegwayIOT)?_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('div',[_c('oto-typography',{attrs:{"text":_vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.unlockCount'
                ),"variant":"two"}})],1),_c('div',{staticClass:" flex"},[_c('oto-typography',{attrs:{"text":("" + (_vm.details.lock.unlock_count - 2000)),"fallback":"--","variant":"three"}}),_c('div',{staticClass:"cursor-pointer pl-3",on:{"click":function($event){return _vm.handleUnlockCountEdit('unlock_count')}}},[_c('svg',{staticClass:"w-4 h-5",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"}})])])],1)]):_vm._e(),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.firmwareKey'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.firmware_key,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.firmwareType'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.firmware_type,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.description'
              ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.lock.description,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockQRCode'
              ),"variant":"two"}}),_c('oto-typography',{staticClass:"break-all",attrs:{"text":_vm.details.lock.qr_code,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid py-2 md:grid-cols-6 sm:grid-cols-3"},[_c('div',{staticClass:"md:col-span-3 sm:col-span-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.macAddress'
                ),"variant":"two"}})],1),_c('div',{staticClass:"md:col-span-2 sm:col-span-2"},[_c('oto-typography',{attrs:{"text":_vm.details.lock.lock_mac,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"md:col-span-1 sm:col-span-1 md:ml-auto sm:ml-0"},[_c('oto-download-button',{attrs:{"variant":"green"},on:{"click":function($event){return _vm.handleDownloading('mac')}}},[_c('div',{staticClass:"w-6 h-5 vld-parent"},[(_vm.downloading.mac)?_c('loading',{attrs:{"active":_vm.downloading.mac,"color":"#fff","height":20,"width":20,"opacity":0,"enforce-focus":false,"is-full-page":false},on:{"update:active":function($event){return _vm.$set(_vm.downloading, "mac", $event)}}}):_c('div',[_c('i',{staticClass:"fas fa-download"})])],1)])],1)])]),_c('div',{staticClass:"col-span-4 px-10 py-6 mt-4 bg-white rounded shadow"},[_c('VehicleTagView',{attrs:{"id":_vm.id}})],1),_c('div',{staticClass:"col-span-4"},[_c('info-grid',{attrs:{"title":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.profile.grid.domainInfo.gridTitle'
            ),"data":_vm.getDomain(),"edit-button":false,"grid-class":"grid-cols-1"}})],1)],1),_c('div',{staticClass:"grid grid-cols-2 md:col-span-4 lg:col-span-3"},[_c('div',{staticClass:"col-span-2 bg-white"},[_c('h3',{staticClass:"px-4 py-4 text-xl font-semibold uppercase text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleLocationInfo.gridTitle' ))+" ")]),_c('div',{staticClass:"h-80 relative"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"zoom":17,"center":_vm.latLng,"map-type-id":"terrain"}},[_c('gmap-marker',{staticStyle:{"width":"30px","height":"30px"},attrs:{"position":_vm.latLng,"draggable":false,"animation":1,"icon":_vm.getStyledMarkerIcon(_vm.details.lock.power_level)}}),(_vm.getParkingAreas.length > 0 && _vm.parkingMarkerOpen)?_vm._l((_vm.getParkingAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
                  url: require("@/assets/img/areas_pin/P_icon.png"),
                  scaledSize: { width: 45, height: 55 },
                }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'P')}}})}):_vm._e(),(_vm.getRestrictedAreas.length > 0 && _vm.restrictedMarkerOpen)?_vm._l((_vm.getRestrictedAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
                  url: require("@/assets/img/areas_pin/R_icon.png"),
                  scaledSize: { width: 45, height: 55 },
                }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'R')}}})}):_vm._e(),(_vm.getSlowSpeedAreas.length > 0 && _vm.slowSpeedMarkerOpen)?_vm._l((_vm.getSlowSpeedAreas),function(area,index){return _c('gmap-marker',{key:index,attrs:{"position":_vm.getLatLng(area.center_coords),"icon":{
                  url: require("@/assets/img/areas_pin/S_icon.png"),
                  scaledSize: { width: 45, height: 55 },
                }},on:{"click":function($event){return _vm.toggleMarker(area, -1, 'S')}}})}):_vm._e(),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})])],2),(_vm.getMarkerControlCounter > 0)?_c('div',{staticClass:"area-info"},[_c('div',{staticClass:"flex flex-wrap items-center justify-between px-4 area-body"},[(_vm.getParkingAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.parkingMarkerOpen = !_vm.parkingMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/P_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.parkingMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e(),(_vm.getRestrictedAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.restrictedMarkerOpen = !_vm.restrictedMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/R_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.restrictedMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e(),(_vm.getSlowSpeedAreas.length > 0)?_c('button',{staticClass:"flex items-center justify-between mx-1",on:{"click":function($event){_vm.slowSpeedMarkerOpen = !_vm.slowSpeedMarkerOpen}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/areas_pin/S_icon.png"),"width":"20px"}}),_vm._v(" "+_vm._s(_vm.slowSpeedMarkerOpen ? 'Hide' : 'Show')+" ")]):_vm._e()])]):_vm._e()],1)]),_c('div',{staticClass:"col-span-2 mt-2 bg-white"},[_c('info-grid',{attrs:{"title":_vm.$t(
              'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.gridTitle'
            ),"data":_vm.getVehicleStatus(),"edit-button":false,"grid-class":"grid-cols-1"},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.status'
                  ),"variant":"two"}}),_c('TripStatusButton',{attrs:{"text":_vm.getTripStatus ? 'On Rent' : 'Free',"variant":_vm.getTripStatus ? 'purple' : 'green'}})],1)]},proxy:true}],null,false,1869208660)})],1),_c('div',{staticClass:"col-span-2 px-10 py-6 mt-4 bg-white"},[_c('h3',{staticClass:"pb-4 text-xl font-bold uppercase text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleDetailsManagement.diveIntoTab.profile.grid.enabledFlagInfo.gridTitle' ))+" ")]),_c('vehicle-flags',{attrs:{"flags":_vm.getFlags,"event-enabled":false,"fallback":true}})],1),_c('div',{staticClass:"col-span-2"},[_c('content-section',{staticClass:"py-6"},[_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"text-xl font-semibold uppercase text-oDark"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.gridTitle' ))+" ")])]),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.simPhoneNumber'
                ),"variant":"two"}}),_c('oto-typography',{attrs:{"text":_vm.details.sim_number,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"grid py-2 md:grid-cols-6 sm:grid-cols-3"},[_c('div',{staticClass:"md:col-span-3 sm:col-span-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.simICCIDNumber'
                  ),"variant":"two"}})],1),_c('div',{staticClass:"md:col-span-2 sm:col-span-2"},[_c('oto-typography',{staticClass:"break-all",attrs:{"text":_vm.details.lock.iccid,"fallback":"--","variant":"three"}})],1),_c('div',{staticClass:"md:col-span-1 sm:col-span-1 md:ml-auto sm:ml-0"},[_c('oto-download-button',{attrs:{"variant":"green"},on:{"click":function($event){return _vm.handleDownloading('sim')}}},[_c('div',{staticClass:"w-6 h-5 vld-parent"},[(_vm.downloading.sim)?_c('loading',{attrs:{"active":_vm.downloading.sim,"color":"#fff","height":20,"width":20,"opacity":0,"enforce-focus":false,"is-full-page":false},on:{"update:active":function($event){return _vm.$set(_vm.downloading, "sim", $event)}}}):_c('div',[_c('i',{staticClass:"fas fa-download"})])],1)])],1)]),_c('div',{staticClass:"grid items-center grid-cols-2 py-2"},[_c('oto-typography',{attrs:{"text":_vm.$t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.networkStrength'
                ),"variant":"two"}}),_c('NetworkStrength',{attrs:{"value":_vm.details.lock.network_signal}})],1)])],1)])]):_vm._e(),_c('VehicleAddEdit',{attrs:{"es-id":_vm.addEdit.esId,"stepper-mode":_vm.addEdit.stepperMode,"stepper-step":_vm.addEdit.stepperStep,"primary-key":_vm.addEdit.primaryKey,"primary-key-lock":_vm.addEdit.primaryKeyLock,"raw-data":_vm.addEdit.rawData,"busy":_vm.addEdit.busy},on:{"refresh":_vm.fetchVehicleData}}),_c('EditVehicleInfo',{attrs:{"primaryKey":_vm.details.id,"profileData":_vm.details,"singleUpdateKey":_vm.singleUpdateKey},on:{"refresh":_vm.fetchVehicleData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }